import React from 'react'
import { Redirect } from '@reach/router'
import { NpmLastSeenProductsPage } from '@plurix/ecom-pages'

import useCmsHome from '../hooks/useCmsHome'

export default function Scratch() {
  const { sections } = useCmsHome()

  const isFeatureActive: boolean | undefined = sections?.find(
    (section) => section?.name === 'AlreadyVisitedShelf'
  )?.data?.isActive

  // redirect to 404
  if (!isFeatureActive) {
    const params = new URLSearchParams({
      from: encodeURIComponent('/o-que-te-interessou'),
    }).toString()

    return <Redirect to={`/404?${params}`} noThrow />
  }

  return <NpmLastSeenProductsPage />
}
